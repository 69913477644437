var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-plant", {
                  attrs: { type: "none", name: "plantCd" },
                  model: {
                    value: _vm.searchParam.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "plantCd", $$v)
                    },
                    expression: "searchParam.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-datepicker", {
                  attrs: {
                    range: true,
                    defaultStart: "-6M",
                    defaultEnd: "6M",
                    label: "평가기간",
                    name: "assessmentDates",
                  },
                  model: {
                    value: _vm.searchParam.assessmentDates,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "assessmentDates", $$v)
                    },
                    expression: "searchParam.assessmentDates",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "RAM_ASSESS_TYPE_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "ramAssessTypeCd",
                    label: "평가구분-정기/수시/최초",
                  },
                  model: {
                    value: _vm.searchParam.ramAssessTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "ramAssessTypeCd", $$v)
                    },
                    expression: "searchParam.ramAssessTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    codeGroupCd: "RAM_TECHNIQUE_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "ramTechniqueCd",
                    label: "평가기법",
                  },
                  model: {
                    value: _vm.searchParam.ramTechniqueCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "ramTechniqueCd", $$v)
                    },
                    expression: "searchParam.ramTechniqueCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-process", {
                  attrs: { label: "공정", name: "processCd" },
                  model: {
                    value: _vm.searchParam.processCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "processCd", $$v)
                    },
                    expression: "searchParam.processCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    stepperGrpCd: "IBM_STEP_CD",
                    type: "search",
                    itemText: "stepperMstNm",
                    itemValue: "stepperMstCd",
                    name: "ibmStepCd",
                    label: "개선진행단계",
                  },
                  model: {
                    value: _vm.searchParam.ibmStepCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "ibmStepCd", $$v)
                    },
                    expression: "searchParam.ibmStepCd",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "위험등록부 목록",
            tableId: "riskRegister01",
            columns: _vm.gridRisk.columns,
            data: _vm.gridRisk.data,
            merge: _vm.gridRisk.merge,
            editable: _vm.editable,
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props }) {
                return [
                  props.row["sopImprovementIds"] && props.row["ibmStepNames"]
                    ? _vm._l(
                        _vm.$comm.ibmTagItems(props.row),
                        function (item, index) {
                          return _c(
                            "q-chip",
                            {
                              key: index,
                              staticStyle: {
                                "margin-bottom": "4px !important",
                              },
                              attrs: {
                                outline: "",
                                square: "",
                                color: item.color,
                                clickable: true,
                                "text-color": "white",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openImpr(item, props.row)
                                },
                              },
                            },
                            [
                              _vm._v(" " + _vm._s(item.title) + " "),
                              _c("c-assess-tooltip", {
                                attrs: {
                                  ibmTooltip: item.ibmTooltip,
                                  ibmClassCd: item.ibmClassCd,
                                },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    : void 0,
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }